import "../css/main.scss";
import Typed from "typed.js";

// or get all of the named exports for further usage
// import * as bootstrap from 'bootstrap';

var typed = new Typed("#typed", {
  strings: [
    "are <strong class='text-warning'>hackers.</strong>",
    "are <strong class='text-warning'>artists.</strong>",
    "are <strong class='text-warning'>people.</strong>",
    "are <strong class='text-warning'>lost.</strong>",
    "<strong class='text-warning'>learn.</strong>",
    "<strong class='text-warning'>build.</strong>",
    "<strong class='text-warning'>brak</strong>",
    "<strong class='text-warning'>break.</strong>",
    "<strong class='text-warning'>fix.</strong>",
    "<strong class='text-warning'>do it all again.</strong>",
  ],
  typeSpeed: 100,
  startDelay: 100,
  backSpeed: 50,
  shuffle: false,
  backDelay: 1000,
  loop: true,
  onComplete: () => {
    let cube = document.getElementsByClassName("cube")[0];
    if (cube.classList.contains("no-animation")) {
      cube.classList.remove("no-animation");
    }
  },
});
